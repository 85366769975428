import { SET_COURSE_INSTANCE } from '../types'

const initialState = {
  courseInstance: null,
}

export default function courseInstanceReducer(state = initialState, action) {
  let courseInstanceWithSortedTopics
  if (action.item) {
    courseInstanceWithSortedTopics = { ...action.item }
    if (
      courseInstanceWithSortedTopics &&
      courseInstanceWithSortedTopics.covers
    ) {
      courseInstanceWithSortedTopics.covers.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        // (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )
    }
  }

  switch (action.type) {
    case SET_COURSE_INSTANCE:
      return {
        ...state,
        courseInstance: action.item
          ? courseInstanceWithSortedTopics
          : action.item,
      }
    default:
      return state
  }
}
